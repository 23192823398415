import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import LoginPage from "./components/login/LoginPage";
import Error404Page from "./components/Error404Page/Error404Page";
//import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';


export default function Routing() {
        return(
            //add anew path by using <Route/> element
            <BrowserRouter>
                <Routes>
                    //Routes for login:start
                    <Route path="/login" element={<LoginPage/>} />
                    //Routes for login:end
                    //Service routes:start
                    <Route path="/" element={<LoginPage/>}/>
                    <Route path="*" element={<Error404Page/>}/>
                    //Service routes:end
                </Routes>
            </BrowserRouter>
        )
}